<template>
  <v-row :style="{cursor: 'pointer' }" class="branding mx-auto" justify="center" no-gutters>
    <v-col cols="12">
      <v-row v-if="$vuetify.rtl" justify="center" no-gutters @click="$router.push({name: 'main'})">
        <v-icon :color="iconColor" :size="iconStyle" class="mx-1">mdi-alpha-i-circle</v-icon>
        <v-icon :color="iconColor" :size="iconStyle" class="mx-1">mdi-alpha-f-circle</v-icon>
        <v-icon :color="iconColor" :size="iconSize" class="mx-1">mdi-alpha-k-circle</v-icon>
      </v-row>
      <v-row v-else justify="center" no-gutters @click="$router.push({name: 'main'})">
        <v-icon :color="iconColor" :size="iconSize" class="mx-1">mdi-alpha-k-circle</v-icon>
        <v-icon :color="iconColor" :size="iconStyle" class="mx-1">mdi-alpha-f-circle</v-icon>
        <v-icon :color="iconColor" :size="iconStyle" class="mx-1">mdi-alpha-i-circle</v-icon>
      </v-row>
    </v-col>
    <v-col :class="textColorClass" :style="{cursor: 'pointer' }" cols="12" @click="$router.push({name: 'main'})">
      <p class="my-2 text-h6">موشر الغذا الکویتی</p>
      <p class="my-2 text-subtitle-1">Kuwait Food Index</p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'KurccBrand',
  props: {
    iconColor: {
      type: String,
      required: false,
      default: 'secondary'
    },
    iconSize: {
      type: Number,
      required: false,
      default: 45
    },
    textColorClass: {
      type: String,
      required: false,
      default: 'green--text accent-3'
    }
  },
  computed: {
    iconStyle () {
      return this.iconSize + 'px'
    }
  }
}
</script>

<style lang="scss" scoped>
.branding {
  max-width: fit-content;
}
</style>
